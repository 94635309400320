import Choices from 'choices.js';

//* Apply choices.js library to all multi-select form fields
const ConfigureSelectChoices = (element, includeSearch = false) => {
  function capitalizeFirstLetter(str) {
    const modifiedStr = str.replace('_', ' ', str);
    return modifiedStr
      .split(' ')
      .map(s => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
  }

  const selectChoices = new Choices(element, {
    removeItemButton: false,
    searchEnabled: includeSearch,
    maxItemCount: 1,
    itemSelectText: '',
    sortFn: a => a,
  });

  return selectChoices;
};

export default ConfigureSelectChoices;
