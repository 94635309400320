const SetFixedHeader = (header, offset) => {
  const body = document.querySelector('body');
  const scrollUp = 'scroll-up';
  const scrollDown = 'scroll-down';
  const headerContainer = header.querySelector('.header-container');
  const siteHeaderInfo = headerContainer.getBoundingClientRect();
  const headerHeight = siteHeaderInfo.height;
  let lastScroll = 0;

  window.addEventListener('scroll', () => {
    const currentScroll = window.pageYOffset;

    //* Add the fixed class to the header when you reach its scroll position.
    //* Remove "fixed" when you leave the scroll position
    if (window.pageYOffset > offset) {
      header.classList.add('fixed');
    } else {
      header.classList.remove('fixed');
    }

    //* Add scroll direction class to body on scroll
    if (currentScroll <= headerHeight) {
      body.classList.remove(scrollDown);
      body.classList.remove(scrollUp);
      return;
    }

    if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
      // down
      body.classList.remove(scrollUp);
      body.classList.add(scrollDown);
    } else if (
      currentScroll < lastScroll &&
      body.classList.contains(scrollDown)
    ) {
      // up
      body.classList.remove(scrollDown);
      body.classList.add(scrollUp);
    }
    lastScroll = currentScroll;
  });
};

export default SetFixedHeader;
